function formatDate(date) {
  const parts = date.toString().substr(0,10).split('-');
  const year = parts[0];
  const month = +parts[1] - 1;
  const day = +parts[2];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return `${months[month]} ${day}, ${year}`;
};

export default formatDate;
