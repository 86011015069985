import React from "react"
import "./externalPost.scss"
import calcReadTime from "../../calcReadTime"
import formatDate from "../../formatDate"
import { Link } from "gatsby"


const ExternalPost = ({ type, url, title, description, date, readTime, words }) => {
  const content = <>
    <h4>
      {title}
    </h4>
    <time>{formatDate(date)}</time><span className="read">{readTime || (words && calcReadTime(words))}</span>
    <div className="description">{description}</div></>;
  if (type === 'my') {
    return <Link to={`/${url}`} className="external-post">{content}</Link>
  }
  return <a href={url}
    target={type === 'my' ? '' : '_blank'}
    rel="noopener noreferrer"
    className="external-post">
    {content}
  </a>;
}

export default ExternalPost
