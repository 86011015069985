import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import ExternalPost from "../components/externalPost/externalPost";
import { getPosts } from "../services/getPosts";

const Blog = ({ data, location }) => {
  const posts = [
    {
      url: `https://habr.com/ru/articles/80730/`,
      title: 'Использование нейронной сети Хопфилда для решения простейшей задачи',
      date: '2010-01-14',
      readTime: '9 min',
      description:'В статье рассматривается использование нейронной сети Хопфилда для решения простейшей задачи. Приводится описание алгоритма и его реализация на языке программирования C#.'
    }
  ];

  return <Layout page="Blog" url="blog">
    <SEO title="A Blog by Максим Дубровкин" location={location} />
    <div className="content page">
      <h2>Blog</h2>
      {posts.map(post => {
        return <ExternalPost key={post.url} {...post} />;
      })}
    </div>
  </Layout>;
}

export default Blog;

export const query = graphql`
query {
  ...PostsInformation
}`;
